import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Textarea } from '../components/ui/textarea';

const API_URL = process.env.REACT_APP_API_URL;

const determineRarity = (rate) => {
  if (rate >= 0.01) return 'Common';
  else if (rate >= 0.005) return 'Uncommon';
  else if (rate >= 0.0015) return 'Rare';
  else return 'Jackpot';
};

const rewardSets = {
  astraeaBox: {
    rates: `0.12%	Special Astraea Stella Wear (M)
0.12%	Special Astraea Stella Wear (F)
0.20%	Astraea Stella Wear (M)
0.20%	Astraea Stella Wear (F)
0.23%	Astraea Stella Handwraps (M)
0.23%	Astraea Stella Bracelets (F)
0.23%	Astraea Stella Sandals (M)
0.23%	Astraea Stella Heels (F)
0.23%	Astraea Stella Hanging Array
0.23%	Astraea Stella Eyepatch (Face Accessory Slot Exclusive)
0.15%	Astraea Stella Halo
0.23%	Fireball Effect Change Card (Baby Panda) Coupon
0.23%	Fireball Effect Change Card (Baby Bear) Coupon
0.23%	Fireball Effect Change Card (Transcendent Echo) Coupon
0.23%	Fireball Effect Change Card (Cleansing Conflagration) Coupon
0.23%	Fireball Effect Change Card (Echoing Void) Coupon
0.23%	Fireball Effect Change Card (Spectral Conflagration) Coupon
0.23%	Spirit Transformation Liqueur (Ultramarine Inferno)
0.23%	Spirit Transformation Liqueur (Sparkling Stardust)
0.23%	Spirit Transformation Liqueur (Wild Hellfire)
0.23%	Spirit Transformation Liqueur (Blue Lightning)
0.26%	Mysterious Starlight 2nd Title Coupon
0.26%	Enchanting Starlight 2nd Title Coupon
0.26%	Beautiful Starlight 2nd Title Coupon
0.26%	Pure Starlight 2nd Title Coupon
0.08%	Pure Enchant Scroll Coupon
0.08%	Whisperer Enchant Scroll Coupon
0.08%	Technical Enchant Scroll Coupon
0.08%	Maestro Enchant Scroll Coupon
0.13%	Sorbet Snow Stardust
0.15%	Special Showy Circus Performer's Outfit (M)
0.15%	Special Showy Circus Performer's Outfit (F)
0.15%	Special Sweet Circus Performer's Outfit (M)
0.15%	Special Sweet Circus Performer's Outfit (F)
0.23%	Sweet Circus Performer's Hat
0.23%	Showy Circus Performer's Hat
0.13%	Special Constellation Guardian Dress (F)
0.13%	Special Constellation Guardian Suit (M)
0.20%	Ornate Folding Fan
0.44%	Spirit Transformation Liqueur (Demonic Possession)
0.44%	Spirit Transformation Liqueur (Galaxy)
0.58%	Spirit Transformation Liqueur (Blackbriar)
0.73%	Spirit Transformation Liqueur (Blizzard)
0.73%	Spirit Transformation Liqueur (Aromatic Coffee Beans)
0.73%	Spirit Transformation Liqueur (Demonic Whispers)
0.88%	Spirit Transformation Liqueur (White Crows)
0.88%	Spirit Transformation Liqueur (Leaf Splash)
0.44%	Showy Circus Performer's Outfit (M)
0.44%	Showy Circus Performer's Outfit (F)
0.44%	Sweet Circus Performer's Outfit (M)
0.44%	Sweet Circus Performer's Outfit (F)
0.73%	Circus Performer's Gloves (M)
0.73%	Circus Performer's Gloves (F)
0.73%	Circus Performer's Boots (M)
0.73%	Circus Performer's Heels (F)
0.88%	Constellation Guardian High Heels (F)
0.88%	Constellation Guardian Boots (M)
0.88%	Imperial Cosmic Prince Suit (M)
0.88%	Imperial Cosmic Princess Dress (F)
0.88%	Imperial Cosmic Prince Short Suit (M)
0.88%	Imperial Cosmic Princess Mini Dress (F)
0.88%	Cosmic Princess Dress (F)
0.88%	Cosmic Prince Suit (M)
0.88%	Cosmic Princess Boots (F)
0.88%	Cosmic Prince Shoes (M)
0.88%	Cosmic Princess Starry Cuffs (F)
0.88%	Cosmic Prince Star Gloves (M)
0.88%	Royal Decree Scroll
0.58%	Florist's Staff
0.73%	Florist's Control Bar
1.32%	Constellation Guardian Dress (F)
1.32%	Constellation Guardian Suit (M)
1.46%	Constellation Guardian Wig and Hairpiece (F)
1.46%	Constellation Guardian Wig and Hairpiece (M)
1.46%	Constellation Guardian Wig (F)
1.46%	Constellation Guardian Wig (M)
1.17%	Cosmic Princess Mini Dress (F)
1.17%	Cosmic Prince Short Suit (M)
1.46%	Cosmic Princess Wig (F)
1.46%	Cosmic Prince Wig (M)
1.32%	Cosmic Princess Crown (F)
1.32%	Cosmic Prince Crown (M)
1.46%	Cosmic Prince Wig and Crown (M)
1.46%	Cosmic Princess Wig and Crown (F)
1.32%	Monochromatic White Pack
1.46%	Monochromatic Blue Pack
1.46%	Monochromatic Purple Pack
1.32%	Noblesse Deity Outfit Dye Ampoule Set
1.32%	Abaddon Wear Dye Ampoule Set
1.32%	Moonlight Dreams Outfit Dye Ampoule Set
1.17%	Homestead Portia's Villa
1.32%	Homestead Portia's Home Front Gate
1.32%	Homestead Heavenly Waterfall
1.46%	Homestead Florentine Fountain
1.48%	Speed Walk Potion 40% (30 min)
1.48%	Light Physical Power Potion (2 hrs.)
1.48%	Light Magic Power Potion (2 hrs.)
1.48%	Fixed Color Dye Ampoule
1.48%	Fixed Color Dye Ampoule
1.48%	Fixed Color Dye Ampoule
1.48%	Pet Fixed Color Dye Ampoule
1.48%	Pet Fixed Color Dye Ampoule
1.48%	Pet Fixed Color Dye Ampoule
1.48%	Fixed Color Metal Dye Ampoule
1.48%	Fixed Color Metal Dye Ampoule
1.48%	Fixed Color Metal Dye Ampoule
1.48%	Homestead Golden Sprout (x5)
1.48%	Divine Magic Powder
1.48%	Potent Fine Gem Powder
1.48%	Fine Gem Powder (x3)
1.48%	Spell Book Repair Quill
1.48%	Unlimited Shadow Mission Pass
1.48%	Essence of Phoenix (x10)
1.48%	Pet EXP Potion (1 Day) (Can be traded once)
1.48%	Blue Collar of Mana (Pet)
1.48%	Imp Mini Potion (x2)
1.48%	Boastful Flex Gesture Card
1.48%	Advertise Gesture Card
1.48%	Rage Gesture Card`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  },
  otherworldlyBox: {
    rates: `2.30% 	Guardian Cwn Annwn Whistle
2.30% 	Arbiter Cwn Annwn Whistle
3.07% 	Crystal Whale Whistle
3.07% 	Lightfin Whale Whistle
5.75% 	Lucky Festive Alpaca Whistle
5.75% 	Sunshine Rose Halflinger Whistle
3.07% 	Saber X Smart Key
3.83% 	White Knight X Smart Key
3.83% 	Practice Kart Smart Key
7.66% 	Ceraunus Whistle
7.66% 	Red Eye Ceraunus Whistle
10.34% 	Fallen Fairy Whistle
10.34% 	Winter Alto Whistle
10.34% 	Crystal Chime Deer Whistle
10.34% 	Astral Nimbus Whistle
10.34% 	Tassel Scooter Whistle`,
    costs: {
      1: 4.9,
      3: 14.7,
      10: 45
    },
    batches: [1, 3, 10]
  },
  royalBox: {
    rates:`0.09% 	Special Royal Society Field Outfit (M)
0.09% 	Special Royal Society Field Outfit (F)
0.09% 	Special Royal Society Laboratory Outfit (M)
0.09% 	Special Royal Society Laboratory Outfit (F)
0.23% 	Royal Society Field Outfit (M)
0.23% 	Royal Society Field Outfit (F)
0.23% 	Royal Society Laboratory Outfit (M)
0.23% 	Royal Society Laboratory Outfit (F)
0.10% 	Royal Society Gloves (M)
0.10% 	Royal Society Gloves (F)
0.10% 	Royal Society Shoes (M)
0.10% 	Royal Society Shoes (F)
0.10% 	Royal Society Wig (M)
0.10% 	Royal Society Wig (F)
0.26% 	Royal Society Goggle Cap
0.26% 	Royal Society Stardust
0.26% 	Royal Society Scythe Appearance Scroll
0.26% 	Royal Society Dual Guns Appearance Scroll
0.26% 	Dual Gun Mastery Effect Change Card (Dazzling Shower) Coupon
0.26% 	Bullet Storm Mastery Effect Change Card (Dazzling Shower) Coupon
0.26% 	Spinning Uppercut Effect Change Card (Sparkbreaker) Coupon
0.26% 	Flame Burst Effect Change Card (Searing Jet) Coupon
0.26% 	Fireball Effect Change Card (Blazing Maelstrom) Coupon
0.10% 	Special Seraphic Harmony Attire (F)
0.10% 	Special Seraphic Harmony Attire (M)
0.10% 	Special Seraphic Symphony Attire (F)
0.10% 	Special Seraphic Symphony Attire (M)
0.12% 	Seraphic Cantabile Wig (F)
0.12% 	Seraphic Cantabile Wig (M)
0.10% 	Seraphic Cantabile Bangles (F)
0.10% 	Seraphic Cantabile Bangles (M)
0.10% 	Seraphic Cantabile Boots (F)
0.10% 	Seraphic Cantabile Boots (M)
0.17% 	Battlefield Overture Effect Change Card (Raving Rabbit) Coupon
0.15% 	Fireball Effect Change Card (Rose Nightmare) Coupon
0.17% 	Meteor Strike Effect Change Card (Coinsplosion) Coupon
0.17% 	Mysterious Robe
0.17% 	Hamelin's Tuner (Reforge: Rank 1)
0.17% 	Special Winter Forest Wanderer Outfit (F)
0.17% 	Special Winter Forest Wanderer Outfit (M)
0.12% 	Special Iceborn Noble Outfit (F)
0.12% 	Special Iceborn Noble Outfit (M)
0.29% 	Staff Stance Card
0.29% 	Two-handed Sword/Rapier Stance Card
0.29% 	Two-handed Scythe Stance Card
0.58% 	Knuckles Stance Card
0.58% 	Bow Stance Card
0.58% 	Bare-Handed Stance Card
0.73% 	Seraphic Harmony Attire (F)
0.73% 	Seraphic Harmony Attire (M)
0.73% 	Seraphic Symphony Attire (F)
0.73% 	Seraphic Symphony Attire (M)
0.73% 	Winter Forest Wanderer Boots (F)
0.73% 	Winter Forest Wanderer Boots (M)
0.87% 	Winter Forest Wanderer Normal Wig (F)
0.87% 	Winter Forest Wanderer Normal Wig (M)
0.73% 	Iceborn Noble Outfit (F)
0.73% 	Iceborn Noble Outfit (M)
0.58% 	Iceborn Noble Hand Ornament (F)
0.58% 	Iceborn Noble Hand Ornament (M)
0.58% 	Iceborn Noble Shoes (F)
0.58% 	Iceborn Noble Shoes (M)
0.44% 	Iceborn Noble Wig (F)
0.44% 	Iceborn Noble Wig (M)
0.87% 	Arctic Fox Outfit (F)
0.87% 	Arctic Fox Outfit (M)
0.87% 	Arctic Fox Mask and Hat
0.58% 	Shadow Reaper Mask (Face Accessory Slot Exclusive)
0.87% 	Florentine Gala Emerald Earrings (Face Accessory Slot Exclusive)
0.73% 	Florentine Gala Diamond Earrings (Face Accessory Slot Exclusive)
0.73% 	Glowing Mask (Face Accessory Slot Exclusive)
0.73% 	Hanging Glasses (Face Accessory Slot Exclusive)
1.45% 	Seraphic Cantabile Holy Censer
1.45% 	Seraphic Machina of Benevolence
1.45% 	Cantabile Conductor's Podium
1.31% 	Winter Forest Wanderer Outfit (F)
1.31% 	Winter Forest Wanderer Outfit (M)
1.45% 	Winter Forest Wanderer Gloves (F)
1.45% 	Winter Forest Wanderer Gloves (M)
1.31% 	Fluffy Arctic Fox Outfit (F)
1.31% 	Fluffy Arctic Fox Outfit (M)
1.31% 	Arctic Fox Blue Snowflake Shoes (F)
1.31% 	Arctic Fox White Snowflake Shoes (F)
1.31% 	Arctic Fox Pink Snowflake Shoes (F)
1.31% 	Arctic Fox Blue Snowflake Shoes (M)
1.31% 	Arctic Fox White Snowflake Shoes (M)
1.31% 	Arctic Fox Pink Snowflake Shoes (M)
1.45% 	Fluffy Arctic Fox Gloves (F)
1.45% 	Fluffy Arctic Fox Gloves (M)
1.31% 	Fluffy Arctic Fox Wig (F)
1.31% 	Fluffy Arctic Fox Wig (M)
1.45% 	Fluffy Arctic Fox Wig and Hat (F)
1.45% 	Fluffy Arctic Fox Wig and Hat (M)
1.31% 	Crystal Hammer of Durability
1.31% 	Rusty Hammer of Proficiency
1.31% 	Platinum Hammer of Durability
1.45% 	Royal Striking Dummy
1.45% 	Royal Punching Bag
1.16% 	Royal Bench Press
1.48% 	Speed Walk Potion 40% (30 min)
1.48% 	Light Physical Power Potion (2 Hours)
1.48% 	Light Magic Power Potion (2 hrs.)
1.48% 	Fixed Color Dye Ampoule (#2B2B2B)
1.48% 	Fixed Color Dye Ampoule (#211C39)
1.48% 	Fixed Color Dye Ampoule (#FD4199)
1.48% 	Fixed Color Dye Ampoule (#B39075)
1.48% 	Fixed Color Dye Ampoule (#db9f3d)
1.48% 	Fixed Color Metal Dye Ampoule (#ffffff)
1.48% 	Fixed Color Metal Dye Ampoule (#db9f3d)
1.48% 	Fixed Color Metal Dye Ampoule (#B39075)
1.48% 	Fixed Color Metal Dye Ampoule (#2B2B2B)
1.48% 	Homestead Pink Rose Patch (Full)
1.48% 	Homestead Pink Rose Patch (Partial) x3
1.48% 	Homestead Tulip Patch (Partial)
1.48% 	Homestead Tulip Flowerbed and Display
1.48% 	Focused Red Beam Sword
1.48% 	Focused Rainbow Beam Sword
1.48% 	Focused Blue Beam Sword
1.48% 	Focused White Beam Sword
1.48% 	Focused Green Beam Sword
1.48% 	Stylish Gesture Card
1.48% 	Tremble Gesture Card
1.48% 	Yearning 3 Gesture Card
1.48% 	Purple Prism Box`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  }
};

const GachaSimulator = () => {
  const [selectedSet, setSelectedSet] = useState('astraeaBox');
  const [prizeInput, setPrizeInput] = useState(rewardSets.astraeaBox.rates);
  const [costs, setCosts] = useState(rewardSets.astraeaBox.costs);
  const [batches, setBatches] = useState(rewardSets.astraeaBox.batches);
  const [rewards, setRewards] = useState([]);
  const [awardedItems, setAwardedItems] = useState({});
  const [totalDraws, setTotalDraws] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [filter, setFilter] = useState('');
  const [visitorCount, setVisitorCount] = useState(0);
  const [globalSpent, setGlobalSpent] = useState(0);

  const fetchStats = async () => {
    try {
      const response = await fetch(`${API_URL}/api/stats`);
      const data = await response.json();
      setVisitorCount(data.visitors);
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to fetch stats:', error);
    }
  };

  useEffect(() => {
    const initVisitor = async () => {
      try {
        const response = await fetch(`${API_URL}/api/stats`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: 'visitor' }),
        });
        const data = await response.json();
        setVisitorCount(data.visitors);
        setGlobalSpent(data.totalSpent);
      } catch (error) {
        console.error('Failed to increment visitor:', error);
      }
    };
    initVisitor();
  }, []);

  useEffect(() => {
    parseRewards();
  }, [prizeInput]);

  useEffect(() => {

    setPrizeInput(rewardSets[selectedSet].rates);
    setCosts(rewardSets[selectedSet].costs);
    setBatches(rewardSets[selectedSet].batches);
  }, [selectedSet]);

  const parseRewards = () => {
    const parsedRewards = prizeInput.split('\n').map(line => {
      const parts = line.split(/\t|(?<=\%)\s+/);
      const rateStr = parts[0].trim();
      const item = parts[1].trim();
      const rate = parseFloat(rateStr.replace('%', '').trim()) / 100;
      return { item, rate };
    });
    setRewards(parsedRewards);
  };

  const drawItems = async (drawCount) => {
    const newAwardedItems = { ...awardedItems };
    for (let i = 0; i < drawCount; i++) {
      const random_number = Math.random();
      let cumulativeRate = 0;
      for (const reward of rewards) {
        cumulativeRate += reward.rate;
        if (random_number < cumulativeRate) {
          if (reward.item in newAwardedItems) {
            newAwardedItems[reward.item].count++;
          } else {
            newAwardedItems[reward.item] = {
              count: 1,
              rarity: determineRarity(reward.rate)
            };
          }
          break;
        }
      }
    }
    setAwardedItems(newAwardedItems);
    setTotalDraws(prevTotalDraws => prevTotalDraws + drawCount);

    const batchCost = costs[drawCount] || (1.5 * drawCount);
    setTotalCost(prevTotalCost => prevTotalCost + batchCost);
    
    try {
      const response = await fetch(`${API_URL}/api/stats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: 'spent',
          amount: batchCost 
        }),
      });
      const data = await response.json();
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to update total spent:', error);
    }
  };

  const filteredItems = Object.entries(awardedItems)
    .filter(([item, data]) => filter === '' || data.rarity === filter)
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <div className="p-4 bg-background text-foreground min-h-screen">
      <Card className="mb-4">
        <CardHeader>Dani's Gacha Sim</CardHeader>
        <CardContent>
          <h2 className="mb-2">
            <a
              href="https://repo.danii.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Repo of my dumb projects
            </a>
          </h2>
          <div className="mb-4">
            <Button onClick={() => setSelectedSet('astraeaBox')} className="mr-2">Astraea Stella Box</Button>
            <Button onClick={() => setSelectedSet('otherworldlyBox')} className="mr-2">Otherworldly Guardians Pet Box</Button>
            <Button onClick={() => setSelectedSet('royalBox')} className="mr-2">Royal Society Box</Button>
          </div>
          <h4 className="mb-2">To change the rates/items, copy from the RNG rates table that is linked in every gacha</h4>
          <h4 className="mb-2">Copy only the %s and the names, leave the headers out.</h4>
          <Textarea
            value={prizeInput}
            onChange={(e) => setPrizeInput(e.target.value)}
            className="w-full mb-4"
          />
          <div className="mb-4">
            {batches.map(batchSize => (
              <Button key={batchSize} onClick={() => drawItems(batchSize)} className="mr-2">
                Draw {batchSize}
              </Button>
            ))}
          </div>
          <div className="mt-4 space-y-2">
            <div>Total Visitors: {visitorCount}</div>
            <div>Your Draws: {totalDraws} (${totalCost.toFixed(2)})</div>
            <div>Global Amount Spent: ${globalSpent.toFixed(2)}</div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Results</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setFilter('')} className="mr-2">All</Button>
            <Button onClick={() => setFilter('Common')} className="mr-2">Common</Button>
            <Button onClick={() => setFilter('Uncommon')} className="mr-2">Uncommon</Button>
            <Button onClick={() => setFilter('Rare')} className="mr-2">Rare</Button>
            <Button onClick={() => setFilter('Jackpot')}>Jackpot</Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Prize</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Rarity</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredItems.map(([item, data]) => (
                <TableRow key={item}>
                  <TableCell>{item}</TableCell>
                  <TableCell>{data.count}</TableCell>
                  <TableCell>{data.rarity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default GachaSimulator;
